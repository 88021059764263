
    .figure {
        background: #f6f5ee;
        position: absolute;
        display: block;
        width: 400px;
        margin: 100px auto;
    top: 25%;
    left: 50%;
    margin: 0 0 0 -200px;
    }

    .figure:before {
        content: "";
        position: absolute;
        display: block;
        width: 132%;
        height: 132%;
        top: -16%;
        left: -16%;
        background-color: #f8f8f8;
        z-index: -1;
        box-shadow:
            inset 0px 10px .5em rgba(0,0,0,.25),
            inset .1em 0px .1em rgba(0,0,0,.1),
            inset -0.1em 0px .1em rgba(0,0,0,.05),
            0 .3em .2em #fff;
        background-image: -moz-radial-gradient(50% 50%, circle farthest-corner, #ffffff, #f8f8f8 100%);
        background-image: -webkit-radial-gradient(50% 50%, circle farthest-corner, #ffffff, #f8f8f8 100%);
        background-image: -o-radial-gradient(50% 50%, circle farthest-corner, #ffffff, #f8f8f8 100%);
        background-image: -ms-radial-gradient(50% 50%, circle farthest-corner, #ffffff, #f8f8f8 100%);
        background-image: radial-gradient(50% 50%, circle farthest-corner, #ffffff, #f8f8f8 100%)
    }

    .figure:after {
        content: "";
        position: absolute;
        display: block;
        width: 145%;
        height: 145%;
        top: -22.5%;
        left: -22.5%;
        background: #f8f8f8;
        z-index: -2;
        box-shadow:
            0px 1em 2em -1em rgba(0,0,0,.4),
            0px 2em 2em -1em rgba(0,0,0,.3),
            0px 3em 2em -1em rgba(0,0,0,.2),
            0px 4em 1.5em -1em rgba(0,0,0,.15),
            0px 2em 4em .5em rgba(0,0,0,.1),
            inset 0 .2em .1em #fff;
    }

    .img {
        background: #fff;
        position: relative;
        display: block;
        width: 88%;
        height: auto;
        padding: 6%;
        box-shadow: inset 0px .3em .1em rgba(0,0,0,.2); }    